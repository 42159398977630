import React, {useState} from 'react';
import {
    MapContainer,
    TileLayer,
    Marker,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import marker from './images/paddleandball.png';
import { Icon } from 'leaflet'
import CustomModal from "./CustomModal";
import { getAuth } from 'firebase/auth'
const myIcon = new Icon({
    iconUrl: marker,
    iconSize: [64, 64]
});

const MapComponent = () => {
    const auth = getAuth();
    const [open, setOpen] = useState(false);
    const position: [number, number] = [59.438684, 24.728917];
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <MapContainer center={position} zoom={19} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position} icon={myIcon} eventHandlers={{ click: handleOpen }}>
                <CustomModal open={open} handleClose={handleClose} />
            </Marker>
            <CustomModal open={open} handleClose={handleClose} />
        </MapContainer>
    );
};

export default MapComponent;
