import React from 'react';
import './App.css';
import MapComponent from './components/MapComponent';
import 'leaflet/dist/leaflet.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { initializeApp } from 'firebase/app'
import {config} from "./config/config";


initializeApp(config.firebaseConfig);

export interface IAppProps {}

const App: React.FunctionComponent<IAppProps> = (props) => {
    return (
        <div>
            <MapComponent/>
        </div>
    );
};

export default App;