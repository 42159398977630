import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import React, { FC, useState } from 'react';
import Stack from '@mui/material/Stack';
import {
    FormControlLabel,
    Modal,
    styled,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl, Checkbox, Divider, Button
} from '@mui/material';
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import {useNavigate} from "react-router-dom";
import GoogleIcon from '@mui/icons-material/Google';

const StyledModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const Paper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: 'relative',
}));

const Header = styled('div')(({ theme }) => ({
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
}));

const DividerLine = styled(Divider)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300],
    margin: theme.spacing(1, 0),
}));

const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date, dateString);
};

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().subtract(1, 'day').endOf('day');
};

const CustomModal: FC<{ open: boolean; handleClose: () => void }> = ({ open, handleClose, }) => {
    const [showComponents, setShowComponents] = useState(false);

    const handleLooUusSyndmusClick = () => {
        setShowComponents(!showComponents);
    };

    const auth = getAuth();
    const [authing, setAuthing] = useState(false);

    const signInWithGoogle = async () => {
        setAuthing(true);
        signInWithPopup(auth, new GoogleAuthProvider)
            .then(response => {
                console.log(response.user.uid)
            })
            .catch( error => {
                console.log(error)
                setAuthing(false)
            })
    }

    return (
        <StyledModal open={open}
                     onClose={handleClose}
                     BackdropProps={{ style: { backgroundColor: 'transparent' } }}>
            <Paper>
                <Header>Telliskivi Loomelinnak</Header>
                <DividerLine />
                <Button onClick={handleLooUusSyndmusClick}>Loo uus sündmus</Button>
                {showComponents && (
                    <Stack spacing={2}>
                        <Stack spacing={1} direction="row">
                            <FormControlLabel control={<Checkbox defaultChecked={false} />} label="Turniir" />
                        </Stack>
                        <TextField
                            id="filled-multiline-flexible"
                            label="Pealkiri"
                            multiline
                            maxRows={1}
                            variant="filled"
                        />
                        <TextField
                            id="filled-multiline-flexible"
                            label="Kirjeldus"
                            multiline
                            maxRows={4}
                        />
                        <Stack spacing={1} direction="row">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Kestvus tundides</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Kestvus tundides"
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={11}>11</MenuItem>
                                    <MenuItem value={12}>12</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <DatePicker
                            placeholder="Vali algusaeg"
                            style={{ height: '64px' }}
                            disabledDate={disabledDate}
                            showTime={{ format: 'HH:mm' }}
                            onChange={onChange}
                            getPopupContainer={(trigger) => trigger.parentElement || document.body}
                        />
                        <TextField
                            id="outlined-number"
                            label="Mängijate arv"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    max: 100, min: 1
                                }
                            }}
                        />
                        <Stack spacing={2} direction="row">
                            <Button startIcon={<GoogleIcon />} variant="text" disabled={authing} onClick={() => signInWithGoogle()} >Logi sisse</Button>
                            <Button variant="outlined">Logi välja</Button>
                            <Button variant="contained">Salvesta</Button>
                        </Stack>
                    </Stack>
                )}
            </Paper>
        </StyledModal>
    );
};

export default CustomModal;
