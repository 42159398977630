export const config = {
    firebaseConfig:  {
        apiKey: "AIzaSyARbh-Dfgr-jbLlvZ2iUY01nhbp6S5NWRE",
        authDomain: "pinks-d549b.firebaseapp.com",
        projectId: "pinks-d549b",
        storageBucket: "pinks-d549b.appspot.com",
        messagingSenderId: "453515655165",
        appId: "1:453515655165:web:4b2cac16e9512f7e59cb94",
        measurementId: "G-Z2CY81R88N"
    }
}